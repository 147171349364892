// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-form-item>
    <!-- WP Implementation -->
    <a-spin :spinning="loading">
    <a-list
      class="form-item-scroll"
      itemLayout="vertical"
      size="small"
      :dataSource="osList"
      :pagination="false">
      <template #renderItem="{ item, index }">
        <a-list-item :key="item.id" @click="onClickRow(item)">
          <a-radio-group
            :key="index"
            v-model:value="value"
            @change="($event) => updateSelectionTemplateIso($event.target.value)">
            <a-radio
              class="radio-group__radio"
              :id="item.id"
              :value="item.id">
              <resource-icon
                v-if="item.icon && item.icon.base64image"
                class="radio-group__os-logo"
                :image="item.icon.base64image"
                size="1x" />
              <os-logo
                v-else
                class="radio-group__os-logo"
                :osId="item.ostypeid"
                :os-name="item.osName" />
              <span v-if="item.icon && item.icon.base64image" style="margin-left: 14px;"></span>
              {{ item.displaytext }}&nbsp;
              <span v-for="tag in item.licencetaginfo" :key="tag.tagText">
                <a-tag v-if="(selectedRadio !== item.id ? true : tag.tagType === 'OS' || tag.tagType === 'Software Package')" :color="tag.tagColor" :id="tag.id">{{ tag.tagText }}&nbsp;</a-tag>
              </span>
              <div style="margin-top: 5px;" v-if="item.licencetaginfo && selectedRadio === item.id && (item.licencetaginfo.filter(x => x.tagType === 'Software per User').length >=1 || item.licencetaginfo.filter(x => x.tagType === 'Software per Core').length >=1)">
                <WarningOutlined style="color: red; margin: 0 0.2rem 0 0.5rem"/>
                {{ $t('message.please.specify.softwarelicences') }} <br> {{ $t('message.liable.to.prosecution') }}
              </div>
              <div
                v-for="tag in item.licencetaginfo"
                :key="tag.id">
                <!-- if template has a "Software per User" Tag -->
                <div class="sliderInput" v-if="tag.tagType === 'Software per User' && selectedRadio === item.id">
                  <a-tag :color="tag.tagColor">{{ tag.tagText }}&nbsp;</a-tag>
                  <a-slider
                    name="userSlider"
                    style="width: 100%;"
                    :min="1"
                    :max="1000"
                    @change="passLicencesToIsoSelection(tag.id)"
                    v-model:value="licenceAmount[tag.id]"/>
                  <a-input-number
                    class="inputField"
                    style="margin-left: 1rem; width: 20em;"
                    :maxLength="4"
                    :min="1"
                    :max="1000"
                    oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                    @change="passLicencesToIsoSelection(tag.id)"
                    v-model:value="licenceAmount[tag.id]"
                    required
                  />
                  <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.licences') }}</span>
                </div>
                <!-- if template has a "Software per Core" Tag -->
                <div class="sliderInput" v-if="tag.tagType === 'Software per Core' && selectedRadio === item.id">
                  <a-tag :color="tag.tagColor">{{ tag.tagText }}&nbsp;</a-tag>
                  <a-slider
                    name="coreSlider"
                    style="width: 100%;"
                    :min="1"
                    :max="selectedCpuNumber"
                    @change="passLicencesToIsoSelection(tag.id, true)"
                    v-model:value="licenceAmount[tag.id]"/>
                  <a-input-number
                    class="inputField"
                    style="margin-left: 1rem;"
                    :maxLength="String(selectedCpuNumber).length"
                    :min="1"
                    :max="selectedCpuNumber"
                    oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                    @change="passLicencesToIsoSelection(tag.id, true)"
                    v-model:value="licenceAmount[tag.id]"
                    required
                  />
                  <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.metrics.num.cpu.cores') }}</span>
                </div>
              </div>
            </a-radio>
          </a-radio-group>
        </a-list-item>
      </template>
    </a-list>
  </a-spin>
    <!-- <div style="display: block; text-align: right;">
      <a-pagination
        size="small"
        :current="options.page"
        :pageSize="options.pageSize"
        :total="itemCount"
        :showTotal="total => `${$t('label.total')} ${total} ${$t('label.items')}`"
        :pageSizeOptions="['10', '20', '40', '80', '100', '200']"
        @change="onChangePage"
        @showSizeChange="onChangePageSize"
        showSizeChanger>
        <template #buildOptionText="props">
          <span>{{ props.value }} / {{ $t('label.page') }}</span>
        </template>
      </a-pagination>
    </div> -->
    <!-- /WP Implementation -->
  </a-form-item>
</template>

<script>
import OsLogo from '@/components/widgets/OsLogo'
import ResourceIcon from '@/components/view/ResourceIcon'
// WP Implementation
import wpapi from '../../../wpApi/myApi'
import { WarningOutlined } from '@ant-design/icons-vue'

export default {
  name: 'TemplateIsoRadioGroup',
  components: {
    OsLogo,
    ResourceIcon,
    WarningOutlined
  },
  // /WP Implementation
  props: {
    osList: {
      type: Array,
      default: () => []
    },
    inputDecorator: {
      type: String,
      default: ''
    },
    selected: {
      type: String,
      default: ''
    },
    itemCount: {
      type: Number,
      default: 0
    },
    preFillContent: {
      type: Object,
      default: () => {}
    },
    // WP Implementation
    selectedCpuNumber: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      licenceAmount: {},
      selectedRadio: '',
      licenceTags: [],
      loading: false,
      // /WP Implementation
      value: '',
      image: '',
      options: {
        page: 1,
        pageSize: 10
      }
    }
  },
  mounted () {
    this.onSelectTemplateIso()
    // WP Implementation
    if (this.osList.length > 0) {
      this.onClickRow(this.osList[0])
    }
  },
  watch: {
    selected (newVal, oldVal) {
      if (newVal === oldVal) return
      this.onSelectTemplateIso()
    },
    // take the osList from TemplateIsoSelection and add the tag Information for each tag from the selected Template
    osList (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
      this.onClickRow(this.osList[0])
      // /WP Implementation
    }
  },
  methods: {
    // WP Implementation
    async getTagsbyTemplateID (templateID) {
      if (this.inputDecorator === 'templateid') {
        await wpapi.getTagsByTemplateUuid(templateID).then(response => {
          this.licenceTags = response.data
        })
          .catch(error => {
            this.$notifyError(error)
          })
      } else {
        this.licenceTags = []
      }
    },
    /**
     * Pass the number of Software Licenses from this component to the DeployVM.vue component as a object with the license ids as parameter.
     * The function is called when the number of a license changes.
     *
     * @param {String} tagID Id of the software License Tag
     * @param {Boolean} isCoreLicense is the license a "license per core" or not
     * @public
     */
    passLicencesToIsoSelection (tagId, isCoreLicense) {
      if (this.licenceAmount[tagId] > 1000) {
        this.licenceAmount[tagId] = 1000
      }
      if (this.licenceAmount[tagId] < 1 || this.licenceAmount[tagId] === '') {
        this.licenceAmount[tagId] = 1
      }
      if (isCoreLicense && this.licenceAmount[tagId] > this.selectedCpuNumber) {
        this.licenceAmount[tagId] = this.selectedCpuNumber
      }/*  else if (tagId && (this.licenceAmount[tagId] < 1 || this.licenceAmount[tagId] === '')) {
        this.licenceAmount[tagId] = 1
      } */
      this.$emit('emit-get-licences-from-radio-grp', this.licenceAmount)
    },
    // /WP Implementation
    onSelectTemplateIso () {
      if (this.inputDecorator === 'templateid') {
        this.value = !this.preFillContent.templateid ? this.selected : this.preFillContent.templateid
      } else {
        this.value = !this.preFillContent.isoid ? this.selected : this.preFillContent.isoid
        this.$emit('emit-update-template-iso', this.inputDecorator, this.value)
      }
    },
    // WP Implementation
    async updateSelectionTemplateIso (id) {
      this.selectedRadio = id
      await this.getTagsbyTemplateID(id)
      this.passLicencesToIsoSelection()
      await this.$emit('emit-update-template-iso', this.inputDecorator, id, this.licenceTags)
    },
    // /WP Implementation
    onChangePage (page, pageSize) {
      this.options.page = page
      this.options.pageSize = pageSize
      this.$emit('handle-search-filter', this.options)
    },
    onChangePageSize (page, pageSize) {
      this.options.page = page
      this.options.pageSize = pageSize
      this.$emit('handle-search-filter', this.options)
    },
    // WP Implementation
    /**
     * Fired, when clicking on a different template in the template selection list.
     * - Set the clicked radio button as the selected one.
     * - Set the number of licenses for "Software per Core" and "Software per User" Licenses to 1 as default
     * - call passLicencesToIsoSelection()
     * - emit UpdateTemplateIso function
     * @param {Object} item Object of the template that was clicked on
     * @public
     */
    async onClickRow (item) {
      // this prevents the reset of the license Slider after mouse off.
      this.value = item.id
      if (this.value !== this.selectedRadio) {
        this.selectedRadio = this.value
        // when changeing selection: for each tag per user or core, will the licenceAmount Object with the id and 1 license as default value for the input fields
        var tempObject = {}
        for (var tag in item.licencetaginfo) {
          if (item.licencetaginfo[tag].tagType === 'Software per Core' || item.licencetaginfo[tag].tagType === 'Software per User') {
            tempObject[item.licencetaginfo[tag].id] = 1
          }
        }
        this.licenceAmount = tempObject
        this.passLicencesToIsoSelection()
        await this.getTagsbyTemplateID(this.selectedRadio)
      }
      await this.$emit('emit-update-template-iso', this.inputDecorator, this.value, this.licenceTags)
    }
    // /WP Implementation
  }
}
</script>

<style lang="less" scoped>

// WP Implementation
 .sliderInput {
    display: flex;
    align-items: center;
    margin-left: 0.55rem;
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
  }
  // /WP Implementation
  .radio-group {
    margin: 0.5rem 0;

    :deep(.ant-radio) {
      margin-right: 20px;
    }

    &__os-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 2px;
      margin-left: 23px;
    }
  }

  :deep(.ant-spin-container) {
    max-height: 200px;
    overflow-y: auto;
  }

  .pagination {
    margin-top: 20px;
    float: right;
  }

  :deep(.ant-list-split) .ant-list-item {
    cursor: pointer;
  }
</style>
